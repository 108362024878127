.periods-table-wrapper thead .MuiTableCell-root {
  padding: 8px 12px !important;
}

.periods-table-wrapper tbody .MuiTableCell-root {
  padding: 12px 12px !important;
}

.periods-table-wrapper .MuiTableCell-root:nth-child(1) {
  width: 120px !important;
}

.periods-table-wrapper .MuiTableRow-root td:nth-child(2) {
  padding: 12px 4px !important;
  width: auto !important;
}

.periods-table-wrapper .MuiTableRow-root td:nth-child(3) {
  padding: 12px 4px !important;
  width: auto !important;
}

.periods-table-wrapper .MuiTableRow-root .MuiTableCell-root:nth-child(4) {
  padding: 12px 12px 12px 4px !important;
  width: 50px !important;
}
