.flex {
  display: flex;
}

.flex-horizontal {
  display: flex !important;
  flex-direction: row;
}

.flex-vertical {
  display: flex !important;
  flex-direction: column;
}

.flex-centered {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-1 {
  flex: 1;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-row-centered {
  display: flex;
  align-items: center;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.margin-top {
  margin-top: 16px;
}

.full-size {
  width: 100%;
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.min-full-height {
  min-height: 100%;
}

.background-covered {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.white {
  color: white;
}