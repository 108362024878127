@import './helpers.css';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

html,
body {
  min-height: 100%;
}

body {
  border: solid 1px blueviolet;
  display: flex;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: 'Oswald', sans-serif !important;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
}

.MuiButton-root {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 400 !important;
}

.MuiInput-root {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 300 !important;
}

.MuiTypography-root {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 300 important;
}

.MuiTableCell-head {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 300 !important;
}

.MuiTableCell-body {
  font-family: 'Oswald', sans-serif !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}

#root {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100%;
  border: solid 0.1px transparent;
  width: 100%;
  min-height: 100%;
  background: rgb(8, 44, 73);
  background: linear-gradient(135deg, #004c95, #7acbff);
}

#root .MainPage {
  margin-left: auto;
  margin-right: auto;
  width: 600px;
  max-width: 100%;
}

#root > .MuiContainer-root {
  height: auto;
}

.App {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Oswald', sans-serif !important;
}

h3 {
  font-weight: 400;
}

.MuiFilledInput-root {
  /* background: rgb(207, 231, 255) !important; */
  background: rgb(247, 251, 255) !important;
}

.flex-form {
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.flex-form .MuiFormControl-root {
  width: 100%;
}

.flex-form .MuiButtonBase-root {
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s;
}

th {
  font-weight: bold !important;
}

.MuiCollapse-root {
  width: 100%;
}

.MuiAccordionDetails-root {
  padding: 0 0 !important;
}

.total>th {
  font-size: 18px;
  font-weight: bold;
}

.MuiBottomNavigationAction-root img {
  height: 40px;
}

.MuiBox-root {
  max-width: 100%;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
  max-width: 100%;
}

.MuiTable-root {
  table-layout: fixed;
  max-width: 100% !important;
  min-width: 0 !important;
}

[role='tabpanel'] {
  min-height: 170px;
}

[role='tabpanel']>.MuiBox-root {
  padding: 0 32px;
}

.MuiAppBar-positionStatic {
  box-shadow: none !important;
  background-color: #0a4061;
  border-bottom: solid 1px #333 !important;
}

.MuiStepLabel-label {
  /*color: white !important;*/
}

.MuiStepLabel-iconContainer .Mui-completed {
  color: #c3ffee !important;
}

.snackbar {}

.snackbar--error .MuiSnackbarContent-root {
  background-color: #bb0000 !important;
  color: white;
}

.snackbar--success .MuiSnackbarContent-root {
  background-color: #056c0a !important;
  color: white;
}

.MemberDashboard {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
}

.MemberDashboard .TicketingEventCard {
  min-width: 100%;
  width: 100%;
}