.Purchase {
  padding: 0 !important;
  height: 300px;

  .MuiCollapse-wrapperInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__payment-method-button {
    width: 100px;
    height: 75px;
    padding: 4px;
    overflow: hidden;
    padding: 16px;

    &--selected {
      background-color: rgba(25, 118, 210, 0.2) !important;
      border: solid rgba(25, 118, 210, 0.8) 2px !important;
    }

    .MuiButton-startIcon {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: calc(100% - 16px) !important;
        max-height: calc(100% - 16px) !important;
      }
    }
  }

  .MuiFormControl-root,
  .MuiButton-root {
    max-width: 200px;
  }

  &__summary {
    margin: 0 !important;
    padding: 0 12px !important;
  }
}

.loading-container {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  font-size: 18px;
}
