.TicketingEventCard {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  min-height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1) !important;

  .MuiCardMedia-media {
    min-height: 350px;
    max-height: 200px;
    height: auto;
  }

  &--compact {
    min-height: 200px;
    max-height: 400px;

    .cm-event-card__description {
      display: none;
    }
  }

  .cm-event-card__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      font-size: 14px !important;
    }
  }

  &.cm-event-card--template-image {
    //min-height: 100px;
    max-height: 100vh;
    height: auto;

    .cm-event-card__description {
      display: none;
    }

    img {
      max-height: 100%;
    }

    >.MuiCardContent-root {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .cm-event-card__description {
    max-height: 200px;
    overflow-y: auto;
  }

  .MuiCardContent-root {
    padding: 0px !important;
  }

  .MuiCard-root {
    .MuiBox-root {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .MuiBox-root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
  }

  .MuiCardHeader-root,
  .MuiContainer-root {
    padding: 8px 12px;
  }

  .MuiCardHeader-subheader {
    display: flex;
    align-items: center;
    gap: 9px;
  }

  .MuiCardActions-root {
    height: 40px;

    button {
      padding: 5px 10px !important;
      height: 100%;
      flex: 1;
      background-color: #005cb5;
      color: white !important;

      &.Mui-disabled {
        // background-color: #aaccee !important;
        cursor: not-allowed;
        pointer-events: all;
      }
    }
  }
}

.TicketingEventCard__my-tickets,
.TicketingEventCard__purchase-tickets {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(230, 240, 255, 1);
  overflow-y: scroll !important;
  // padding-bottom: 60px !important;
}

.TicketingEventCard__my-tickets {
  .MuiMobileStepper-root {
    padding: 4px 0px;
  }

  button {
    background-color: unset !important;
  }
}

.TicketingEventCard__actions {
  // position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 60px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  button {
    height: 40px;
  }
}