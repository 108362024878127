.PurchaseStateSummary {
  padding: 0 !important;

  .mbreference-card {
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }

  .mbreference-icon-container {
    height: 50px;
  }

  .mbreference-data > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  &__status-message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 6px;
    margin-left: -18px;
    margin-top: 24px;
  }
}
