.TicketCard {
  position: relative;
  height: 215px;
  min-height: 215px;
  display: flex;
  flex-direction: column;

  &__header {
    height: 45px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
  }

  &__content {
    height: 45px;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    padding: 10px 16px 12px 16px;
  }

  &__state-overlay {
    font-size: 40px;
    font-weight: bold;

    z-index: 10;
    position: absolute;
    top: 48%;
    left: 75%;
    transform: rotate(-15deg) translate(-50%, -50%);

    &--unpaid {
      color: rgba(140, 80, 80, 0.9);
    }

    &--used {
      color: rgba(80, 140, 80, 0.9);
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 48%;
  }

  &__description {
    flex: 1;
  }

  &__price {
    height: 30px;
    font-size: 1.25rem;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-x: hidden;
  }
}

.TicketCard__qrcode {
  position: absolute;
  right: 0px;
  bottom: 0px;

  width: 170px;
  height: 170px;

  &--inactive {
    opacity: 0.2;
  }
}
