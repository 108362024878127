.ticket-office {
  &__ticketing-event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    gap: 32px;
    padding-bottom: 16px;
  }

  &__ticketing-event-loading {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .MuiSkeleton-root {
      width: 100%;
      max-width: 300px;
      height: 100px;
    }
  }

  &__ticketing-event-empty {
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .MuiSvgIcon-root {
      font-size: 3rem;
      opacity: 0.5;
    }
  }
}
