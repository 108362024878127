.account-create-wrapper {
  padding: 16px 0;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
}

.account-create-wrapper.account-create-wrapper button {
  color: white !important;
  background: transparent !important;
  background-color: transparent !important;
}

.account-create-wrapper .MuiCollapse-wrapperInner {
  display: flex;
}

.account-create-wrapper .MuiInputBase-root {
  background-color: white;
}

.account-create-wrapper .completed-message {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  margin: 0 32px;
  color: white !important;
}

.account-create-wrapper .completed-message .MuiSvgIcon-root {
  font-size: 64px;
}

.account-create-wrapper a {
  text-decoration: none;
  color: white;
  font-size: 16px;
}

.ok-button {
  background: none !important;
  background-color: transparent !important;
}