.membership-card-wrapper {
  aspect-ratio: 2 / 1.2;
  background: linear-gradient(170deg, #c0e7ff, #6bb6fb);
}

.membership-card-wrapper .MuiCardContent-root {
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: 60% 40%;
  height: 100%;
  box-sizing: border-box;
  gap: 0;
  padding: 8px !important;

  > div {
    padding: 4px !important;

    @media screen and (max-width: 400px) {
      padding: 4px;
    }
  }
}

.member-card {
  position: relative;
  display: flex;
  flex-direction: row;
}

.member-card-icon {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.member-card-icon > img {
  max-height: 100%;
  max-width: 100%;
}

.member-card-picture {
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  > div {
    height: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 3px;
  }
}

.member-card-qrcode {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  > img {
    height: 100%;
    aspect-ratio: 1 / 1;
  }
}

.member-card-member-text-data {
  grid-column: 2 / 2;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right !important;
}

.member-card-number {
  font-weight: 400;
  font-size: 2.5em;

  @media screen and (max-width: 500px) {
    font-size: 1.5em;
  }
}

.member-card-name {
  font-weight: 300;
  font-size: 1.7em;

  @media screen and (max-width: 500px) {
    font-size: 1.2em;
  }
}
