.membership-periods-wrapper {
  margin: 0px 0 16px 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.membership-periods-wrapper .MuiPaper-root {
  border-radius: 4px !important;
}

.membership-periods-wrapper .MuiBox-root > div[role='tabpanel'] {
  min-height: 200px;
  padding: 16px;
}

.membership-periods-wrapper .MuiBottomNavigation-root {
  padding: 8px 16px;
}

.membership-periods-details {
  margin: 0px 0 16px 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
