.TicketsBuyCart {
  &__item-button {
    background-color: white !important;
    height: 70px;
    padding: 0px 8px !important;

    &--selected {
      background-color: rgba(25, 118, 210, 0.2) !important;
      border: solid rgba(18, 98, 190, 1) 1px !important;
    }
  }

  &__proceed-button {
    margin-top: 8px !important;
  }
}
