.profile-wrapper {
  display: flex !important;
  flex-direction: column;
  gap: 16px !important;
  justify-content: flex-start;
  align-items: center;

  .MuiCardActions-root,
  .MuiCardContent-root {
    flex: 1;
    padding: 16px !important;

    @media screen and (max-width: 400px) {
      padding: 10px !important;
    }
  }
}

.profile-wrapper .MuiAvatar-root {
  width: 200px;
  height: 200px;
}

.profile-form-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
